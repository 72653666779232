import React from "react";
import { Form, Field, FormProps } from "react-final-form";
import { Input, Row, Col, Button, Space, Typography } from "antd";
import { ShowError } from "components/EnrollmentForm/Fields";
import {
  composeValidators,
  required,
  validateCellNumber,
} from "components/EnrollmentForm/validators";
import Logo from "components/Logo";
import styles from "./index.module.scss";

const { Title, Text } = Typography;

export interface Values {
  account: string;
  password: string;
}
interface LoginFormProps extends FormProps<Values> {
  isCountingDown: boolean;
  counter: number;
  onRequestCode: (id: string) => any;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  onRequestCode,
  isCountingDown,
  counter,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      {({
        handleSubmit,
        submitting,
        pristine,
        errors,
        values,
        submitError,
      }) => {
        return (
          <form onSubmit={handleSubmit} className={styles["login-form"]}>
            <Row align="middle" className={styles["title-row"]}>
              <Col>
                <Logo />
              </Col>
              <Col>
                <div className={styles.title}>
                  <Title level={4} style={{ margin: 0 }}>
                    上海民航职业技术学院
                    <br />
                    {new Date().getFullYear()}年招生在线报名
                  </Title>
                </div>
              </Col>
            </Row>
            <Space direction="vertical" className={styles.main}>
              <Row align="middle">
                <Col span={3}>手机号</Col>
                <Col flex="auto">
                  <Field
                    name="account"
                    validate={composeValidators(required, validateCellNumber)}
                  >
                    {({ input, meta }) => (
                      <Row align="middle">
                        <Col flex="auto">
                          <Input {...input} placeholder="请输入手机号" />
                        </Col>
                        <Col>
                          {meta.touched && <ShowError error={meta.error} />}
                        </Col>
                      </Row>
                    )}
                  </Field>
                </Col>
              </Row>
              <Row align="middle">
                <Col span={3}>验证码</Col>
                <Col flex="auto">
                  <Field name="password" validate={composeValidators(required)}>
                    {({ input }) => (
                      <Row align="middle">
                        <Col flex="auto">
                          <Input {...input} placeholder="请输入验证码" />
                        </Col>
                        <Col>
                          <Field name="account">
                            {({ meta }) => (
                              <Button
                                type="primary"
                                ghost
                                onClick={() => onRequestCode(values.account)}
                                disabled={isCountingDown || meta.error}
                              >
                                {isCountingDown
                                  ? `重新获取 ${counter}S`
                                  : "获取验证码"}
                              </Button>
                            )}
                          </Field>
                        </Col>
                      </Row>
                    )}
                  </Field>
                </Col>
              </Row>
              <Row justify="center">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={
                    pristine || submitting || Object.keys(errors).length > 0
                  }
                >
                  登录
                </Button>
              </Row>
              {submitError && (
                <Row justify="center">
                  <Text type="danger">{submitError}</Text>
                </Row>
              )}
            </Space>
          </form>
        );
      }}
    </Form>
  );
};

export default LoginForm;
