// export const staticSiteUrl = "http://cdn.qixueyuan.com/scac-ors/";

export const apiUrl = process.env.REACT_APP_API_URL;

// export const pdfLink = new URL(
//   "上海民航职业技术学院2020年空乘空保专业网上面试信息表和承诺书.pdf",
//   staticSiteUrl
// ).href;

// const ladyPhotoPath = new URL(
//   "SCAC空中乘务（女）手持证件照模板.jpg",
//   staticSiteUrl
// ).href;

// const gentlemanPhotoPath = new URL(
//   "SCAC民航空中安全保卫（男）手持证件照模板.jpg",
//   staticSiteUrl
// ).href;

// const ladyVideoPath = new URL(
//   "女生版-SCAC空中乘务专业网上面试视频模板.mp4",
//   staticSiteUrl
// ).href;

// const gentlemanVideoPath = new URL(
//   "男生版-SCAC民航空中安全保卫专业网上面试视频模板.mp4",
//   staticSiteUrl
// ).href;

// 2021
// "https://hanxue-cdn.oss-cn-hangzhou.aliyuncs.com/scac-ors/%E4%B8%8A%E6%B5%B7%E6%B0%91%E8%88%AA%E8%81%8C%E4%B8%9A%E6%8A%80%E6%9C%AF%E5%AD%A6%E9%99%A22021%E5%B9%B4%E7%A9%BA%E4%B9%98%E7%A9%BA%E4%BF%9D%E4%B8%93%E4%B8%9A%E7%BD%91%E4%B8%8A%E9%9D%A2%E8%AF%95%E4%BF%A1%E6%81%AF%E8%A1%A8%E5%92%8C%E6%89%BF%E8%AF%BA%E4%B9%A6.pdf";
// 2022
export const pdfLink =
  "https://hanxue-cdn.oss-cn-hangzhou.aliyuncs.com/scac-ors/%E4%B8%8A%E6%B5%B7%E6%B0%91%E8%88%AA%E8%81%8C%E4%B8%9A%E6%8A%80%E6%9C%AF%E5%AD%A6%E9%99%A22022%E5%B9%B4%E7%A9%BA%E4%B9%98%E7%A9%BA%E4%BF%9D%E4%B8%93%E4%B8%9A%E7%BD%91%E4%B8%8A%E9%9D%A2%E8%AF%95%E4%BF%A1%E6%81%AF%E8%A1%A8%E5%92%8C%E6%89%BF%E8%AF%BA%E4%B9%A6.pdf";

// 2021
// "Announcements are read over the loudspeaker system on aircraft at specified time, which are determined by the purser on each flight."
// 2022
export const readingSentence =
  "Excuse me, could you please make room for other passengers to pass?Your seat number is 4A, in the front of the cabin. This way please.";

export const ladyPhotoPath =
  "https://hanxue-cdn.oss-cn-hangzhou.aliyuncs.com/scac-ors/SCAC%E7%A9%BA%E4%B8%AD%E4%B9%98%E5%8A%A1%EF%BC%88%E5%A5%B3%EF%BC%89%E6%89%8B%E6%8C%81%E8%AF%81%E4%BB%B6%E7%85%A7%E6%A8%A1%E6%9D%BF.jpg";

export const gentlemanPhotoPath =
  "https://hanxue-cdn.oss-cn-hangzhou.aliyuncs.com/scac-ors/SCAC%E6%B0%91%E8%88%AA%E7%A9%BA%E4%B8%AD%E5%AE%89%E5%85%A8%E4%BF%9D%E5%8D%AB%EF%BC%88%E7%94%B7%EF%BC%89%E6%89%8B%E6%8C%81%E8%AF%81%E4%BB%B6%E7%85%A7%E6%A8%A1%E6%9D%BF.jpg";

export const ladyVideoPath =
  "https://hanxue-cdn.oss-cn-hangzhou.aliyuncs.com/scac-ors/%E5%A5%B3%E7%94%9F%E7%89%88-SCAC%E7%A9%BA%E4%B8%AD%E4%B9%98%E5%8A%A1%E4%B8%93%E4%B8%9A%E7%BD%91%E4%B8%8A%E9%9D%A2%E8%AF%95%E8%A7%86%E9%A2%91%E6%A8%A1%E6%9D%BF.mp4";

export const gentlemanVideoPath =
  "https://hanxue-cdn.oss-cn-hangzhou.aliyuncs.com/scac-ors/%E7%94%B7%E7%94%9F%E7%89%88-SCAC%E6%B0%91%E8%88%AA%E7%A9%BA%E4%B8%AD%E5%AE%89%E5%85%A8%E4%BF%9D%E5%8D%AB%E4%B8%93%E4%B8%9A%E7%BD%91%E4%B8%8A%E9%9D%A2%E8%AF%95%E8%A7%86%E9%A2%91%E6%A8%A1%E6%9D%BF.mp4";

export const provinces = [
  "北京",
  "天津",
  "河北省",
  "山西省",
  "内蒙古自治区",
  "辽宁省",
  "吉林省",
  "黑龙江省",
  "上海",
  "江苏省",
  "浙江省",
  "安徽省",
  "福建省",
  "江西省",
  "山东省",
  "河南省",
  "湖北省",
  "湖南省",
  "广东省",
  "广西壮族自治区",
  "海南省",
  "重庆",
  "四川省",
  "贵州省",
  "云南省",
  "西藏自治区",
  "甘肃省",
  "陕西省",
  "青海省",
  "宁夏回族自治区",
  "新疆维吾尔族自治区",
  "台湾省",
  "香港特别行政区",
  "澳门特别行政区",
];
