import React from "react";
import styles from "./Photo.module.scss";

interface Props {
  title?: string;
  src: string;
  alt: string;
}

const Photo = ({ title, src, alt }: Props) => {
  return (
    <div className={styles.photo}>
      <img className={styles.img} src={src} alt={alt} />
      {title && <p className={styles.title}>{title}</p>}
    </div>
  );
};

export default Photo;
