import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
// import { useRecoilValue } from "recoil";
// import { authState } from "../recoil/authState";

const PrivateRoute: React.FC<
  RouteProps & { loginUrl?: string; isAuthenticated: boolean }
> = ({ loginUrl = "/login", isAuthenticated, ...routeProps }) => {
  // const isAuthenticated = useRecoilValue(authState);

  return isAuthenticated ? (
    <Route {...routeProps} />
  ) : (
    <Redirect
      to={{ pathname: loginUrl, state: { from: routeProps.location } }}
    />
  );
};

export default PrivateRoute;
