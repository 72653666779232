import React from "react";
import { Typography } from "antd";
import { useRecoilValue } from "recoil";
import { siteInfoState } from "state";
import styles from "./index.module.scss";

const { Paragraph } = Typography;

const Note = () => {
  const { activeProvinces } = useRecoilValue(siteInfoState);

  return activeProvinces.length ? (
    <div className={styles.note}>
      <Paragraph strong>
        {new Date().getFullYear()}年我院空乘空保专业招生省市:
      </Paragraph>
      <Paragraph>{activeProvinces.join(", ")}</Paragraph>
    </div>
  ) : null;
};

export default Note;
