import React, { CSSProperties } from "react";
import clsx from "clsx";
import styles from "./Progress.module.scss";

interface Props {
  percentage: number;
  style?: CSSProperties;
  className?: string;
}

const Progress = ({ style, percentage, className }: Props) => (
  <div className={clsx(className, styles.progress)} style={style}>
    <div
      className={styles["progress-bar"]}
      style={{ width: `${percentage}%` }}
    ></div>
  </div>
);

export default Progress;
