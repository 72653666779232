import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  authState,
  initialState,
  isAuthenticatedState,
  siteInfoState,
} from ".";

export const useAuth = () => {
  const auth = useRecoilValue(authState);

  return auth;
};

export const useToken = () => {
  const auth = useRecoilValue(authState);

  return auth.accessToken ?? "";
};

export const useIsAuthenticated = () => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);

  return isAuthenticated;
};

export const useLogin = () => {
  const setAuthState = useSetRecoilState<AuthState>(authState);

  const request = useCallback(() => {
    setAuthState((oldState) => ({ ...oldState, isFetching: true }));
  }, [setAuthState]);

  const done = useCallback(
    (userName: string, roles: Array<string>, accessToken: string) => {
      setAuthState({
        isFetching: false,
        userId: userName,
        userName,
        roles,
        accessToken,
      });
    },
    [setAuthState]
  );

  const fail = useCallback(
    (error: any) => {
      setAuthState((oldState) => ({
        ...oldState,
        isFetching: false,
        error,
      }));
    },
    [setAuthState]
  );

  const logout = useCallback(() => {
    setAuthState({ ...initialState });
  }, [setAuthState]);

  return {
    request,
    done,
    fail,
    logout,
  };
};

export const useLogout = () => {
  const setAuthState = useSetRecoilState<AuthState>(authState);

  const logout = () => {
    setAuthState({ ...initialState });
  };

  return useCallback(logout, [setAuthState]);
};

// // reducers
// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case types.LOGIN_REQUEST:
//       return { ...initialState, isFetching: true };
//     case types.LOGIN_SUCCESS:
//       const { userName, roles, accessToken } = action.payload;
//       return {
//         isFetching: false,
//         userId: userName,
//         userName,
//         roles,
//         accessToken,
//       };
//     case types.LOGIN_FAILURE:
//       return { ...initialState, error: action.payload };
//     case types.LOGOUT:
//       return { ...initialState };
//     default:
//       return state;
//   }
// };

export const useSiteInfo = () => {
  const site = useRecoilValue(siteInfoState);
  return site;
};
