import React, { useState, useEffect } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Radio, Space, Button, Typography, Select } from "antd";
import { getCandidate, setCandidate } from "api";
import { useSiteInfo, useToken } from "state/hooks";
import { ShowError, InputRow, RadioGroupRow, FieldRow } from "./Fields";
import {
  composeValidators,
  required,
  minLength,
  maxLength,
  email,
  number,
  validateNceeNumber,
  validateIdCardNumber,
  validateCellNumber,
  verifyIdCardNumberAvailability,
} from "./validators";
import styles from "./index.module.scss";

const { Option } = Select;
const { Title, Paragraph } = Typography;

interface Props {
  next: () => void;
}
const EnrollmentForm = ({ next }: Props) => {
  const token = useToken();
  const site = useSiteInfo();
  const [initialValues, setInitialValues] = useState<Partial<Candidate>>();
  const { activeProvinces } = site;

  const submit = async (values: Candidate) => {
    const { error } = await setCandidate(token)(values);
    if (error) return { [FORM_ERROR]: "提交失败，请稍后重试" };
    next();
  };

  useEffect(() => {
    const getInfo = async () => {
      if (typeof initialValues === "undefined") {
        const { error, data } = await getCandidate(token)();
        if (!error) setInitialValues(data || {});
        else setInitialValues({});
      }
    };

    getInfo();
  }, [initialValues, token]);

  if (typeof initialValues === "undefined") return <div>正在加载。。。</div>;

  return (
    <Form onSubmit={submit} initialValues={initialValues}>
      {({ handleSubmit, submitting, submitError, values }) => (
        <form onSubmit={handleSubmit}>
          <Title level={3}>报名表</Title>
          <Paragraph strong>请完整、准确的填写全部字段</Paragraph>
          <Space
            className={styles["form-content"]}
            direction="vertical"
            size="middle"
          >
            <InputRow
              label="姓名"
              name="name"
              placeholder="姓名"
              validate={composeValidators<string>(required, minLength(2))}
            />
            <RadioGroupRow label="性别" name="gender" validate={required}>
              {({ input }) => (
                <Radio.Group {...input}>
                  <Radio value="男">男</Radio>
                  <Radio value="女">女</Radio>
                </Radio.Group>
              )}
            </RadioGroupRow>
            <InputRow
              label="民族"
              name="nation"
              placeholder=""
              validate={composeValidators<string>(required, maxLength(8))}
            />
            <InputRow
              label="身份证号"
              name="idCardNumber"
              placeholder="一个身份证号码仅可报名一次，不可多次报名"
              validate={composeValidators(
                required,
                validateIdCardNumber,
                verifyIdCardNumberAvailability(token)
              )}
            />
            <InputRow
              label="高考报名号"
              name="nceeNumber"
              placeholder="十四位高考报名号"
              validate={validateNceeNumber}
            />
            <FieldRow<string>
              label="高考科类"
              name="nceeType"
              placeholder=""
              validate={required}
            >
              {({ input }) => (
                <Select {...input} className={styles.select}>
                  <Option value="文科">文科</Option>
                  <Option value="理科">理科</Option>
                  <Option value="综合">综合</Option>
                </Select>
              )}
            </FieldRow>
            <FieldRow<string>
              label="高考所在省市"
              name="nceeProvince"
              placeholder=""
              validate={required}
            >
              {({ input }) => (
                <>
                  <Select {...input} className={styles.select}>
                    {activeProvinces.map((province, index) => (
                      <Option key={index} value={province}>
                        {province}
                      </Option>
                    ))}
                  </Select>
                  <span className={styles.hint}>
                    仅显示空乘空保专业招生省市
                  </span>
                </>
              )}
            </FieldRow>
            <InputRow
              label="毕业学校"
              name="graduatedFrom"
              placeholder=""
              validate={composeValidators<string>(
                required,
                minLength(4),
                maxLength(50)
              )}
            />
            <InputRow
              label="净身高（cm）"
              name="height"
              placeholder=""
              validate={composeValidators<string>(
                required,
                number,
                maxLength(8)
              )}
            />
            <InputRow
              label="体重（kg）"
              name="weight"
              placeholder=""
              validate={composeValidators<string>(
                required,
                number,
                maxLength(8)
              )}
            />
            <InputRow
              label="本人邮箱号"
              name="email"
              placeholder=""
              validate={composeValidators(required, email, maxLength(50))}
            />
            <InputRow
              label="本人手机号"
              name="cellNumber"
              placeholder=""
              validate={composeValidators(required, validateCellNumber)}
            />
            <InputRow
              label="家长手机号"
              name="parentCellNumber"
              placeholder=""
              validate={composeValidators(required, validateCellNumber)}
            />
            <InputRow
              label="通讯地址"
              name="mailAddress"
              placeholder=""
              validate={composeValidators<string>(
                required,
                minLength(10),
                maxLength(100)
              )}
            />
            <RadioGroupRow
              label="是否动过手术"
              name="hadSurgery"
              validate={required}
            >
              {({ input }) => (
                <Radio.Group {...input}>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              )}
            </RadioGroupRow>
            <RadioGroupRow
              label="是否色盲色弱"
              name="colorWeaknessOrBlindness"
              validate={required}
            >
              {({ input }) => (
                <Radio.Group {...input}>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              )}
            </RadioGroupRow>
            <RadioGroupRow
              label="是否过敏性鼻炎"
              name="allergicRhinitis"
              validate={required}
            >
              {({ input }) => (
                <Radio.Group {...input}>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              )}
            </RadioGroupRow>
            <RadioGroupRow label="是否有纹身" name="tattoo" validate={required}>
              {({ input }) => (
                <Radio.Group {...input}>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              )}
            </RadioGroupRow>
            <div className={styles.buttons}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={submitting}
                disabled={submitting || Object.keys(values).length === 0}
              >
                提交表格并继续下一步
              </Button>
              <ShowError error={submitError} />
            </div>
          </Space>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </form>
      )}
    </Form>
  );
};

export default EnrollmentForm;
