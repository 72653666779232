import React from "react";
import { Row, Col, Typography } from "antd";
import Logo from "./Logo";
import styles from "./Closed.module.scss";

const { Title, Paragraph } = Typography;

const Closed = () => {
  return (
    <div className={styles.closed}>
      <div className={styles.main}>
        <Row align="middle" className={styles["title-row"]}>
          <Col>
            <Logo />
          </Col>
          <Col>
            <div className={styles.title}>
              <Title level={4} style={{ margin: 0 }}>
                上海民航职业技术学院
                <br />
                {new Date().getFullYear()}年招生在线报名
              </Title>
            </div>
          </Col>
        </Row>

        <Title level={4}>通知</Title>

        <Paragraph>
          <ol>
            <li>
              在线报名系统当前处于关闭时段。
              请广大考生根据自身高考所在省市，按照我院公布的时间范围来此提交报名材料。
            </li>
            <li>
              招生咨询电话：021-64572049 34693230 <br />
              学院招生信息网：zs.shcac.edu.cn <br />
              学院招生微信公众号：mh34693230
              <div>
                <img
                  src="/mp_scac.png"
                  alt="scac mp code"
                  className={styles["wechat-code"]}
                />
              </div>
            </li>
          </ol>
        </Paragraph>

        <Paragraph className={styles.close}>
          上海民航职业技术学院招生办 <br />
          {new Date().getFullYear()}年1月
        </Paragraph>
      </div>
    </div>
  );
};

export default Closed;
