import { requestOssSignature } from "api";
// import { OssSignature } from "../types";

export const randomString = (length: number = 32) => {
  const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = chars.length;
  let str = "";
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return str;
};

export const getSuffix = (filename: string) => {
  const pos = filename.lastIndexOf(".");
  let suffix = "";
  if (pos !== -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
};

export const sendFile =
  (token: string) =>
  async ({
    type,
    file,
    fileName,
    onProgress,
    onLoad,
    onError,
  }: {
    type: "photo" | "video" | "letter";
    file: File;
    fileName: string;
    onProgress: (ev: ProgressEvent) => any;
    onLoad: (ev: ProgressEvent) => any;
    onError?: (ev: ProgressEvent) => any;
  }) => {
    const { error, data } = await requestOssSignature(token)(type);

    if (error) {
      console.log("request oss signature error", error);
      return;
    }
    const {
      host,
      dir,
      policy,
      accessid,
      //callback,
      signature,
    } = data as OssSignature;

    // 顺序重要
    const json = {
      name: file.name,
      key: dir + fileName,
      policy,
      OSSAccessKeyId: accessid,
      success_action_status: "200", //让服务端返回200,不然，默认会返回204
      signature: signature,
      // callback,
      file,
    };

    const payload = new FormData();
    Object.entries(json).forEach(
      ([key, value]) => value && payload.append(key, value)
    );

    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = onProgress;
    xhr.upload.onload = onLoad;
    xhr.upload.onerror = onError ?? null;

    // XHR - Make Request
    xhr.open("POST", host, true);
    xhr.send(payload);
  };
