import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { getUploads, getFileUrls, confirmSubmission } from "api";
import { useToken } from "state/hooks";
import styles from "./Confirmation.module.scss";

const { Title, Paragraph, Text } = Typography;

interface Props {
  prev: () => void;
  next: () => void;
}

const Confirmation = ({ prev, next }: Props) => {
  const token = useToken();
  const [uploads, setUploads] = useState<Uploads>();
  const [fileUrls, setFileUrls] = useState<FileUrls>();
  const [isUploading, setIsUploading] = useState(false);

  const confirm = async () => {
    setIsUploading(true);
    const { error, data } = await confirmSubmission(token)();
    setIsUploading(false);

    if (!error && data) {
      next();
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      if (typeof uploads === "undefined") {
        const { error, data } = await getUploads(token)();

        if (!error && data) {
          setUploads(data);
        }
      }
    };

    getInfo();
  }, [token, uploads]);

  useEffect(() => {
    const getInfo = async () => {
      if (typeof fileUrls === "undefined") {
        const { error, data } = await getFileUrls(token)();

        if (!error && data) {
          setFileUrls(data);
        }
      }
    };

    getInfo();
  }, [token, fileUrls]);

  return (
    <div>
      <Title level={3}>资料确认</Title>
      <Title level={4}>请确认上传的资料准确无误，确认提交后不可再修改</Title>
      <Paragraph>
        {uploads ? (
          <ol>
            <li>
              {uploads.letterFileName} (
              {uploads.letterSize?.toLocaleString("en")}字节）
              {fileUrls?.letterUrl && (
                <div className={styles["material-box"]}>
                  <img
                    className={styles.material}
                    src={fileUrls?.letterUrl}
                    alt="letter preview"
                  />
                </div>
              )}
            </li>
            <li>
              {uploads.photoFileName} ({uploads.photoSize?.toLocaleString("en")}
              字节）
              {fileUrls?.photoUrl && (
                <div className={styles["material-box"]}>
                  <img
                    className={styles.material}
                    src={fileUrls?.photoUrl}
                    alt="preview"
                  />
                </div>
              )}
            </li>
            <li>
              {uploads.videoFileName} ({uploads.videoSize?.toLocaleString("en")}
              字节）
              {fileUrls?.videoUrl && (
                <div className={styles["material-box"]}>
                  <video controls className={styles.video}>
                    <source src={fileUrls?.videoUrl} type="video/mp4" />
                  </video>
                </div>
              )}
            </li>
          </ol>
        ) : (
          "正在加载。。。"
        )}
      </Paragraph>

      <div className={styles.buttons}>
        <Button type="default" size="large" onClick={prev}>
          返回上一步
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={confirm}
          disabled={isUploading}
        >
          确认提交
        </Button>
        <Text type="danger" strong>
          注意：提交后不可再修改
        </Text>
      </div>
    </div>
  );
};

export default Confirmation;
