import formUrlEncode from "lib/formUrlEncode";
import { apiUrl } from "config";

/**
 * 获取站点信息
 */
export const getSiteInfo = async () => {
  const url = "/site";
  const headers = {
    Accept: "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "GET",
    headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
  });

  if (res.ok) {
    const resJson: SiteInfo = await res.json();

    return {
      error: "",
      data: resJson,
    };
  } else {
    return {
      error: "not found",
      data: null,
    };
  }
};

/**
 * 获取考生entity
 */
export const getCandidate = (token: string) => async () => {
  try {
    const url = "/candidate";
    const headers = {
      Accept: "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "GET",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
    });

    if (res.ok) {
      const resJson: Candidate = await res.json();

      return {
        error: "",
        data: resJson,
      };
    }
  } catch {}

  return {
    error: "not found",
    data: undefined,
  };
};

/**
 * 创建或更新 考生entity
 */
export const setCandidate = (token: string) => async (candidate: Candidate) => {
  const url = "/candidate";
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify(candidate),
  });

  if (res.ok) {
    const resJson: Candidate = await res.json();

    return {
      error: "",
      data: resJson,
    };
  } else {
    return {
      error: res.statusText,
      data: null,
    };
  }
};

/**
 * 确认提交资料
 */
export const confirmSubmission = (token: string) => async () => {
  const url = "/candidate/submission";
  const headers = {
    Accept: "application/json",
  };

  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
  });

  if (res.ok) {
    const resJson: Candidate | undefined = await res.json();

    return {
      error: "",
      data: resJson,
    };
  } else {
    return {
      error: res.statusText,
      data: undefined,
    };
  }
};

/**
 * 检查身份证是否可用
 */
export const verifyIdCardNumber =
  (token: string) => async (idCardNumber: string) => {
    const url = "/candidate/id-card-number-available/" + idCardNumber;
    const headers = {
      Accept: "application/json",
    };

    const res = await fetch(apiUrl + url, {
      method: "GET",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
    });

    if (res.ok) {
      const resJson: boolean = await res.json();

      return {
        error: "",
        data: resJson,
      };
    } else {
      return {
        error: res.statusText,
        data: false,
      };
    }
  };

/**
 * 获取 OSS直传的服务端签名
 */
export const requestOssSignature = (token: string) => async (type: string) => {
  const url = "/oss/signature";
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ type }),
  });

  if (res.ok) {
    const resJson: OssSignature = await res.json();
    return {
      error: "",
      data: resJson,
    };
  } else {
    return {
      error: res.statusText,
      data: null,
    };
  }
};

/**
 * oss直传完成后，由oss直接回调服务器
 * 此处模拟oss回调
 */
export const callOssCallback =
  (token: string) => async (payload: OssCallbackPayload) => {
    const url = "/oss/callback";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    };

    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: formUrlEncode(payload),
    });

    const resJson: OssSignature = await res.json();

    return {
      error: "",
      data: resJson,
    };
  };

/**
 * 获取验证码，验证码直接发送到用户手机
 * @param accountId 手机号
 */
export const requestCode = async (accountId: string) => {
  const url = "/account/code";
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ accountId }),
  });

  if (res.ok) return { error: "" };

  return {
    error: res.statusText,
  };
};

/**
 * 用户登录
 * @param accountId 账号（手机号）
 * @param password 密码
 */
export const login: LoginFn = async (account: string, password: string) => {
  const url = "/account/login";
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ account, password }),
  });

  if (res.ok) {
    const resJson = await res.json();
    return { error: "", data: resJson };
  }

  return {
    error: res.statusText,
  };
};

/**
 * 获取考生 上传资料信息
 */
export const getUploads = (token: string) => async () => {
  try {
    const url = "/candidate/upload";
    const headers = {
      Accept: "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "GET",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
    });

    if (res.ok) {
      const resJson: Uploads = await res.json();

      return {
        error: "",
        data: resJson,
      };
    }
  } catch {}

  return {
    error: "not found",
    data: null,
  };
};

/**
 * 获取考生 上传资料的Urls
 */
export const getFileUrls = (token: string) => async () => {
  const url = "/candidate/fileUrls";
  const headers = {
    Accept: "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "GET",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
  });

  if (res.ok) {
    const resJson: FileUrls = await res.json();

    return {
      error: "",
      data: resJson,
    };
  } else {
    return {
      error: "not found",
      data: null,
    };
  }
};
