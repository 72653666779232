import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./MyDropZone.module.scss";

const PlusIcon = () => <div className={styles["plus-icon"]}></div>;

interface Props {
  name: string;
  onChange: (f: File) => void;
  type: "image" | "video" | "pdf";
}

const MyDropzone = ({ name, onChange, type }: Props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange(acceptedFiles[0]);
    },
    [onChange]
  );
  const accept = {
    image: "image/jpeg, image/png",
    video: "video/*",
    pdf: "application/pdf",
  }[type];

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  return (
    <div {...getRootProps()} className={styles["my-drop-zone"]}>
      <PlusIcon />
      <input {...getInputProps()} name={name} />
    </div>
  );
};

export default MyDropzone;
