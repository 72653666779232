import { atom, selector } from "recoil";

// const skipLogin = true;
// const skipLogin = false;
//
// const initialStateForTest: AuthState = {
//   isFetching: false,
//   userId: "18621872665",
//   userName: "18621872665",
//   roles: ["candidate"],
//   accessToken:
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjE4NjIxODcyNjY1IiwibmJmIjoxNjQyOTU0MTIxLCJleHAiOjE2NDM1NTg5MjEsImlhdCI6MTY0Mjk1NDEyMX0.pX5AqqtsbTnAFVOeY5jDxwXwrP8XuKNrXPjzxIwe_RI",
//   error: null,
// };

export const initialState: AuthState = {
  isFetching: false,
  userId: undefined,
  userName: undefined,
  roles: [],
  accessToken: undefined,
  error: null,
};

export const authState = atom({
  key: "authState",
  default: { ...initialState },
  // default:
  //   process.env.NODE_ENV === "production" || !skipLogin
  //     ? { ...initialState }
  //     : { ...initialStateForTest },
});

export const isAuthenticatedState = selector({
  key: "isAuthenticatedState",
  get: ({ get }) => {
    return !!get(authState).userId;
  },
});

export const siteInfoState = atom<SiteInfo>({
  key: "siteInfo",
  default: { isOpen: true, activeProvinces: [] },
});
