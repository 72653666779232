import React from "react";
import styles from "./Video.module.scss";

interface Props {
  title?: string;
  src: string;
}
const Video = ({ title, src }: Props) => {
  return (
    <div className={styles["video-box"]}>
      <video className={styles.video} controls>
        <source src={src} type="video/mp4" />
      </video>
      {title && <p className={styles.title}>{title}</p>}
    </div>
  );
};

export default Video;
