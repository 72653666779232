import React, { useState, useRef, useEffect } from "react";
import { Typography, Button } from "antd";
import { callOssCallback } from "api";
import { useToken } from "state/hooks";
import { ladyPhotoPath, gentlemanPhotoPath } from "config";
import sleep from "lib/sleep";
import { sendFile, getSuffix } from "./utils";
import MyDropZone from "./MyDropZone";
import Progress from "./Progress";
import Photo from "./Photo";
import styles from "./UploadPhoto.module.scss";

const { Title, Paragraph, Text } = Typography;
const type = "photo";

interface Props {
  accountId: string;
  prev: () => void;
  next: () => void;
}

const UploadPhoto = ({ accountId, prev, next }: Props) => {
  const token = useToken();
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const newFileNameRef = useRef("");

  const setNewFileName = (filename: string) => {
    const suffix = getSuffix(filename);
    newFileNameRef.current = "手持身份证照" + suffix;
  };

  const onChange = (file: File) => {
    setFile(file);
    setPreview(URL.createObjectURL(file));
  };

  const onLoad = async () => {
    if (file) {
      const ret = await callOssCallback(token)({
        accountId,
        type,
        filename: newFileNameRef.current,
        originName: file.name.slice(0, 100), // 数据库字段长度
        size: file.size,
      });

      console.log("oss callback result", ret);
      await sleep(500);

      setIsUploading(false);
      next();
    }
  };

  const onProgress = (e: ProgressEvent) => {
    const done = e.loaded;
    const total = e.total;
    const perc = Math.floor((done / total) * 1000) / 10;
    // console.log(perc);
    setPercentage(perc);
  };

  const upload = async () => {
    if (file) {
      setIsUploading(true);

      setNewFileName(file.name);
      await sendFile(token)({
        type,
        file,
        fileName: newFileNameRef.current,
        onProgress,
        onLoad,
        onError: () => setIsUploading(false),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  return (
    <div>
      <Title level={3}>手持身份证照</Title>
      <Paragraph>
        以原图形式上传，文件命名为：姓名+手持身份证照，保证本人面部正面以及身份证信息清晰，不得遮盖任何信息，以便核实身份。
      </Paragraph>
      <br />
      <Title level={4}>示例</Title>
      <div className={styles.row}>
        <Photo
          title="手持身份证照模板（女生版）"
          src={ladyPhotoPath}
          alt="lady"
        />
        <Photo
          title="手持身份证照模板（男生版）"
          src={gentlemanPhotoPath}
          alt="gentleman"
        />
      </div>
      <Title level={4}>上传照片</Title>
      <Paragraph>点击或拖拽文件至下框</Paragraph>
      <MyDropZone type="image" onChange={onChange} name="photo" />
      {preview && (
        <div className={styles.uploading}>
          <Photo src={preview} alt="preview" />

          {file?.name && (
            <div className={styles.progress}>
              <Paragraph>文件名：{file.name}</Paragraph>

              <Text>上传进度：{percentage.toFixed(0)}%</Text>
              <Progress percentage={percentage} className={styles.bar} />
            </div>
          )}
        </div>
      )}

      <div className={styles.buttons}>
        <Button type="default" size="large" onClick={prev}>
          返回上一步
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={upload}
          disabled={!file?.name || isUploading}
        >
          开始上传并继续下一步
        </Button>
      </div>
    </div>
  );
};

export default UploadPhoto;
