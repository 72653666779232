import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Redirect, useLocation } from "react-router";
import { FORM_ERROR } from "final-form";
import { useIsAuthenticated, useLogin } from "state/hooks";
import * as api from "api";
import LoginForm, { Values } from "./LoginForm";
import Note from "./Note";
import styles from "./index.module.scss";

const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  const { request, done, fail } = useLogin();
  const location = useLocation<{ from: string }>();
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (isCountingDown) {
      const intervalId = setInterval(
        () => setCounter((counter) => counter - 1),
        1000
      );
      return () => clearInterval(intervalId);
    }
  }, [isCountingDown]);

  useEffect(() => {
    if (counter === 0) setIsCountingDown(false);
  }, [counter]);

  const onSubmit = async ({ account, password }: Values) => {
    request();

    const { data, error } = await api.login(account, password);
    console.log("login", { data, error });

    if (error) {
      fail(error);
      return { [FORM_ERROR]: "验证码不匹配" };
    }

    if (data) {
      console.log("done!", data);
      done(data.userName, data.roles, data.accessToken);
    }
  };

  const onRequestCode = async (accountId: string) => {
    unstable_batchedUpdates(() => {
      setCounter(60);
      setIsCountingDown(true);
    });

    await api.requestCode(accountId);
  };

  if (isAuthenticated === true) {
    // const { from } = location.state || { from: { pathname: "/" } };
    // if (from.pathname === "/login") from.pathname = "/";
    // return <Redirect to={from} />;
    let from = location.state?.from;
    if (!from || from === "/login") from = "/";
    return <Redirect to={from} />;
  }

  return (
    <div className={styles.login}>
      <LoginForm
        onSubmit={onSubmit}
        onRequestCode={onRequestCode}
        isCountingDown={isCountingDown}
        counter={counter}
      />
      <Note />
    </div>
  );
};

export default Login;
