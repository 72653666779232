/**
 * 对输入进行 application/x-www-form-urlencoded 编码
 * 不支持深入嵌套
 */
const formUrlEncode = (obj: object): string =>
  Object.entries(obj)
    .map(
      ([key, value]) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(value)
    )
    .join("&");

export default formUrlEncode;
