import React, { ReactNode } from "react";
import { Row, Col, Input, Typography } from "antd";
import { Field, FieldRenderProps } from "react-final-form";
import { FieldValidator } from "final-form";
import styles from "./index.module.scss";

const { Text } = Typography;

export const ShowError = ({ error }: { error: string }) => (
  <Text type="danger" className={styles.error}>
    {error}
  </Text>
);

export const InputRow = ({
  label,
  name,
  placeholder,
  validate,
}: {
  label: string;
  name: string;
  placeholder?: string;
  validate?: FieldValidator<string>;
}) => {
  const first = 6,
    second = 18;
  return (
    <Row align="middle">
      <Col span={first}>{label}</Col>
      <Col span={second}>
        <Field name={name} validate={validate}>
          {({ input, meta }) => (
            <Row align="middle">
              <Col flex="auto">
                <Input placeholder={placeholder} {...input} />
              </Col>
              <Col>{meta.touched && <ShowError error={meta.error} />}</Col>
            </Row>
          )}
        </Field>
      </Col>
    </Row>
  );
};

export function RadioGroupRow<T>({
  label,
  name,
  validate,
  children,
}: {
  label: string;
  name: string;
  validate?: FieldValidator<T>;
  children: (p: FieldRenderProps<T, HTMLInputElement>) => ReactNode;
}) {
  const first = 6,
    second = 18;
  return (
    <Row align="middle">
      <Col span={first}>{label}</Col>
      <Col span={second}>
        <Field name={name} validate={validate} type="radiogroup">
          {({ input, meta }) => (
            <Row align="middle">
              {children({ input, meta })}
              {meta.touched && <ShowError error={meta.error} />}
            </Row>
          )}
        </Field>
      </Col>
    </Row>
  );
}

export function FieldRow<T>({
  label,
  name,
  placeholder,
  validate,
  children,
  type = "fieldrow",
}: {
  label: string;
  name: string;
  placeholder?: string;
  validate?: FieldValidator<T>;
  children: (p: FieldRenderProps<T, HTMLElement>) => ReactNode;
  type?: string;
}) {
  const first = 6,
    second = 18;
  return (
    <Row align="middle">
      <Col span={first}>{label}</Col>
      <Col span={second}>
        <Field
          name={name}
          validate={validate}
          placeholder={placeholder}
          type={type}
        >
          {({ input, meta }) => (
            <Row align="middle">
              {children({ input, meta })}
              {meta.touched && <ShowError error={meta.error} />}
            </Row>
          )}
        </Field>
      </Col>
    </Row>
  );
}
