import React from "react";
import { Steps } from "antd";

const { Step } = Steps;

interface Props {
  current?: number;
  className?: string;
}
const ApplicationStatus = ({ current, className }: Props) => (
  <Steps current={current} responsive={false} className={className}>
    <Step title="报名表" />
    <Step title="承诺书" />
    <Step title="手持身份证照" />
    <Step title="视频" />
    <Step title="确认" />
    <Step title="完成" />
  </Steps>
);

export default ApplicationStatus;
