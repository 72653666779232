import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { getCandidate, getUploads } from "api";
import { useAuth, useToken } from "state/hooks";
import ApplicationStatus from "./ApplicationStatus";
import EnrollmentForm from "./EnrollmentForm";
import LetterOfCommitment from "./LetterOfCommitment";
import UploadPhoto from "./UploadPhoto";
import UploadVideo from "./UploadVideo";
import Success from "./Success";
import Confirmation from "./Confirmation";
import styles from "./Main.module.scss";

const { Title, Paragraph } = Typography;

const Main = () => {
  const auth = useAuth();
  const token = useToken();
  const [currentStatus, setCurrentStatus] = useState<number>(-1);
  const accountId = auth.userName ?? "";

  const prev = () =>
    setCurrentStatus(currentStatus > 0 ? currentStatus - 1 : 0);

  const next = () => setCurrentStatus((currentStatus || 0) + 1);

  useEffect(() => {
    const getInfo = async () => {
      if (currentStatus === -1) {
        const { error: errorUploads, data: upload } = await getUploads(token)();
        const { error: errorCandidate, data: candidate } = await getCandidate(
          token
        )();

        if (!errorCandidate && candidate?.submissionConfirmed) {
          return setCurrentStatus(5); // 成功页面
        }

        if (!errorUploads && upload) {
          if (!upload.letterSize) return setCurrentStatus(1); // 上传承诺书
          if (!upload.photoSize) return setCurrentStatus(2); // 上传照片
          if (!upload.videoSize) return setCurrentStatus(3); // 上传视频
          return setCurrentStatus(4); // 确认页面
        }

        return setCurrentStatus(0); // 填写表单页面
      }
    };

    getInfo();
  }, [currentStatus, token]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <Title level={2} className={styles.title}>
          上海民航职业技术学院 {new Date().getFullYear()}
          年空乘空保专业在线报名
        </Title>
        <ApplicationStatus current={currentStatus} className={styles.steps} />
        <div className={styles.content}>
          {currentStatus === -1 ? (
            <Paragraph className={styles.loading}>正在加载。。。</Paragraph>
          ) : currentStatus === 0 ? (
            <EnrollmentForm next={next} />
          ) : currentStatus === 1 ? (
            <LetterOfCommitment accountId={accountId} prev={prev} next={next} />
          ) : currentStatus === 2 ? (
            <UploadPhoto accountId={accountId} prev={prev} next={next} />
          ) : currentStatus === 3 ? (
            <UploadVideo accountId={accountId} prev={prev} next={next} />
          ) : currentStatus === 4 ? (
            <Confirmation prev={prev} next={next} />
          ) : currentStatus === 5 ? (
            <Success />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Main;
