import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { getUploads, getFileUrls } from "api";
import { useToken } from "state/hooks";
import styles from "./Success.module.scss";

const { Title, Paragraph } = Typography;

const Success = () => {
  const token = useToken();
  const [uploads, setUploads] = useState<Uploads>();
  const [fileUrls, setFileUrls] = useState<FileUrls>();

  useEffect(() => {
    const getInfo = async () => {
      if (typeof uploads === "undefined") {
        const { error, data } = await getUploads(token)();

        if (!error && data) {
          setUploads(data);
        }
      }
    };

    getInfo();
  }, [token, uploads]);

  useEffect(() => {
    const getInfo = async () => {
      if (typeof fileUrls === "undefined") {
        const { error, data } = await getFileUrls(token)();

        if (!error && data) {
          setFileUrls(data);
        }
      }
    };

    getInfo();
  }, [token, fileUrls]);

  return (
    <div>
      <Title level={3}>
        在线报名已成功{" "}
        <span role="img" aria-label="done">
          ✅
        </span>
      </Title>
      <Title level={4}>上传资料已确认</Title>
      <Paragraph>
        {uploads ? (
          <ol>
            <li>
              {uploads.letterFileName} (
              {uploads.letterSize?.toLocaleString("en")}字节）
              {fileUrls?.letterUrl && (
                <div className={styles["material-box"]}>
                  <img
                    className={styles.material}
                    src={fileUrls?.letterUrl}
                    alt="letter preview"
                  />
                </div>
              )}
            </li>
            <li>
              {uploads.photoFileName} ({uploads.photoSize?.toLocaleString("en")}
              字节）
              {fileUrls?.photoUrl && (
                <div className={styles["material-box"]}>
                  <img
                    className={styles.material}
                    src={fileUrls?.photoUrl}
                    alt="preview"
                  />
                </div>
              )}
            </li>
            <li>
              {uploads.videoFileName} ({uploads.videoSize?.toLocaleString("en")}
              字节）
              {fileUrls?.videoUrl && (
                <div className={styles["material-box"]}>
                  <video controls className={styles.video}>
                    <source src={fileUrls?.videoUrl} type="video/mp4" />
                  </video>
                </div>
              )}
            </li>
          </ol>
        ) : (
          "正在加载。。。"
        )}
      </Paragraph>
      <Title level={4}>报考资格审核和视频评分</Title>
      <Paragraph>
        <ol>
          <li>
            学院将秉着“公平、公正”原则随机从专家库中抽取评委对考生递交的所有材料依据我院公布的《上海民航职业技术学院
            {new Date().getFullYear()}
            年空中乘务和民航空中安全保卫专业招生简章》进行综合评审，每名考生视频资料将以匿名形式由3-5名评委进行综合打分，整个评分过程全程监督、全程录像。
          </li>
          <li>如遇特殊情况，我院将主动联系考生进行直播面试考核。</li>
        </ol>
      </Paragraph>
      <Title level={4}>其他</Title>
      <Paragraph>
        <ol>
          <li>
            线上提交的各类资料一旦上传成功不允许修改。考生所提供的所有材料必须真实、准确、完整，若因考生提供的材料错误、失真、缺失而造成不良后果，责任由考生本人承担。
          </li>
          <li>
            我院空乘空保专业线上考试均不收取任何费用，请广大考生和家长注意个人财产安全，谨防上当受骗。
          </li>
          <li>
            我院将分省市开通线上考生材料上传工作，请广大考生根据自身高考所在省市按照我院公布的时间范围内上传相关材料，以免造成材料丢失。
          </li>
          <li>
            招生咨询电话：021-64572049 34693230 <br />
            学院招生信息网：zs.shcac.edu.cn <br />
            学院招生微信公众号：mh34693230
            <div>
              <img
                src="/mp_scac.png"
                alt="scac mp code"
                className={styles["wechat-code"]}
              />
            </div>
          </li>
        </ol>
      </Paragraph>
      <Paragraph className={styles.close}>
        上海民航职业技术学院招生办 <br />
        {new Date().getFullYear()}年1月
      </Paragraph>
    </div>
  );
};

export default Success;
