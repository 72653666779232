import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { siteInfoState } from "state";
import { useIsAuthenticated } from "state/hooks";
import { getSiteInfo } from "api";
import PrivateRoute from "lib/PrivateRoute";
import Main from "components/Main";
import Login from "components/Login";
import Closed from "components/Closed";

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const [siteInfo, setSiteInfo] = useRecoilState(siteInfoState);

  useEffect(() => {
    const initSiteInfo = async () => {
      const { data, error } = await getSiteInfo();

      if (error) {
        console.log({ error });
      }

      if (data) {
        setSiteInfo(data);
      }
    };

    initSiteInfo();
  }, [setSiteInfo]);

  useEffect(() => {
    document.title = `上海民航职业技术学院 ${new Date().getFullYear()}年空乘空保专业在线报名`;
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/" exact={true} render={() => <Redirect to="/home" />} />
        <Route path="/login">{siteInfo?.isOpen ? <Login /> : <Closed />}</Route>
        <PrivateRoute path="/home" isAuthenticated={isAuthenticated}>
          <Main />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default App;
