import { FieldValidator, FieldState } from "final-form";
import { verifyIdCardNumber } from "api";

type ComposeValidators = <T>(
  ...validators: FieldValidator<T>[]
) => FieldValidator<T>;

type Validator = <FieldValue>(
  value: FieldValue,
  allValues: object,
  meta?: FieldState<FieldValue>
) => any | Promise<any>;

export const composeValidators: ComposeValidators =
  (...validators) =>
  (value, allValues, meta?) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues, meta),
      undefined
    );

export const required = (value: any) => {
  let hasValue = true;
  //   if (typeof value === "undefined") hasValue = false;
  if (value == null) hasValue = false; // test if null or undefined
  if (typeof value === "string" && !value.length) hasValue = false;

  return hasValue ? undefined : ("必填" as string);
};

interface LengthWise {
  length: number;
}

export const minLength = (length: number) => (value?: LengthWise) =>
  value && value.length < length ? `最少${length}个字符` : undefined;

export const maxLength = (length: number) => (value?: LengthWise) =>
  value && value.length > length ? `最多${length}个字符` : undefined;

export const matchLength = (length: number) => (value?: LengthWise) =>
  value && value.length !== length ? `输入必须为${length}位` : undefined;

export const number = (value: any) =>
  isNaN(value) ? ("必须是数字" as string) : undefined;

export const email = (value: string) =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? ("邮箱格式不正确" as string)
    : undefined;

export const validateIdCardNumber = (value: string) =>
  !/^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}(\d|X)$/.test(
    value
  )
    ? ("身份证号码格式不正确" as string)
    : undefined;

export const verifyIdCardNumberAvailability =
  (token: string) => async (value: string) => {
    const { data } = await verifyIdCardNumber(token)(value);
    if (!data) return "身份证号码已被其他账号使用" as string;

    return undefined;
  };

export const validateCellNumber = (value: string) =>
  !/^1[0-9]{10}$/.test(value) ? ("手机格式不正确" as string) : undefined;

export const validateNceeNumber = (value: string, allValues: object) => {
  //   if ((allValues as Candidate).ignoreNceeNumber) return undefined;

  const validate = composeValidators(required, matchLength(14));

  return validate(value, allValues);
};
