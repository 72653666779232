import React, { useState, useRef, useEffect } from "react";
import { Typography, Button } from "antd";
import { callOssCallback } from "api";
import { useToken } from "state/hooks";
import { ladyVideoPath, gentlemanVideoPath, readingSentence } from "config";
import sleep from "lib/sleep";
import MyDropZone from "./MyDropZone";
import Progress from "./Progress";
import Video from "./Video";
import { sendFile, getSuffix } from "./utils";
import styles from "./UploadVideo.module.scss";

const { Title, Paragraph, Text } = Typography;
const type = "video";

interface Props {
  accountId: string;
  prev: () => void;
  next: () => void;
}

const UploadVideo = ({ accountId, prev, next }: Props) => {
  const token = useToken();
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const newFileNameRef = useRef("");

  const setNewFileName = (filename: string) => {
    const suffix = getSuffix(filename);
    newFileNameRef.current = "视频" + suffix;
  };

  const onChange = (file: File) => {
    setFile(file);
    setPreview(URL.createObjectURL(file));
  };

  const onLoad = async () => {
    if (file) {
      const ret = await callOssCallback(token)({
        accountId,
        type,
        filename: newFileNameRef.current,
        originName: file.name.slice(0, 100), // 数据库字段长度
        size: file.size,
      });

      console.log("oss callback result", ret);
      await sleep(500);

      setIsUploading(false);
      next();
    }
  };

  const onProgress = (e: ProgressEvent) => {
    const done = e.loaded;
    const total = e.total;
    const perc = Math.floor((done / total) * 1000) / 10;
    console.log(perc);
    setPercentage(perc);
  };

  const upload = async () => {
    if (file) {
      setIsUploading(true);

      setNewFileName(file.name);
      await sendFile(token)({
        type,
        file,
        fileName: newFileNameRef.current,
        onProgress,
        onLoad,
        onError: () => setIsUploading(false),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  return (
    <div>
      <Title level={3}>视频</Title>
      <Paragraph>
        考生按我院提供的MP4格式视频模板进行拍摄上传，视频命名为：姓名+视频。务必保证视频资料真实性，确保声音和图像清晰，无抖动。若发现弄虚作假等行为，一经发现，视为作弊，成绩作废，取消报考资格。
        <ol style={{ listStyleType: "decimal" }}>
          <li>视频时长：视频时间控制在90s以内。</li>
          <li>视频大小：80M以内。</li>
          <li>
            视频着装：男生：白色短袖衬衫+深色西裤+黑色皮鞋；女生：白色短袖衬衫+深色一步裙（不过膝盖，不穿丝袜）+黑色皮鞋
          </li>
          <li>
            视频内容 详见我院视频模板，视频中的内容需涵盖模板中的所有环节。
            <br />
            附英语朗读句：{readingSentence}（考生请务必朗读此句，否则影响评分）
          </li>
          <li>
            拍摄要求：
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                拍摄环境安静，无噪音，光线良好，正面受光，避免逆光或强光下拍摄，背景干净，纯色为佳。
              </li>
              <li>
                男女生请素颜出镜，不要刘海，不得佩戴眼镜及配饰，不得戴美瞳和假睫毛等。
              </li>
              <li>视频中只能有考生本人，不应出现除考生本人外的其他人员。</li>
              <li>
                视频必须为原始视频，不得作任何美化剪辑处理，不得开启特效（包括具有脸部美化的美颜、身材比例优化的特效，以及各类滤镜等）、不得进行声音处理等后期制作。
              </li>
            </ol>
          </li>
        </ol>
      </Paragraph>
      <Title level={4}>示例</Title>
      <Video title="视频模板（女生版）" src={ladyVideoPath} />
      <Video title="视频模板（男生版）" src={gentlemanVideoPath} />
      <Title level={4}>上传视频</Title>
      <Paragraph>点击或拖拽文件至下框</Paragraph>
      <MyDropZone type="video" onChange={onChange} name="video" />
      {preview && (
        <div className={styles.uploading}>
          <Video src={preview} />
          {file && file.name && (
            <div className={styles.progress}>
              <Paragraph>文件名：{file && file.name}</Paragraph>

              <Text>上传进度：{percentage.toFixed(0)}%</Text>
              <Progress percentage={percentage} className={styles.bar} />
            </div>
          )}
        </div>
      )}
      <div className={styles.buttons}>
        <Button type="default" size="large" onClick={prev}>
          返回上一步
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={upload}
          disabled={!file?.name || isUploading}
        >
          开始上传并继续下一步
        </Button>
      </div>
    </div>
  );
};

export default UploadVideo;
